<template>
  <v-app>
    <app-navigation></app-navigation>
    <v-main style="padding:80px 0 0">
      <v-row justify="center">
        <v-dialog v-model="showLoginInDialog" max-width="290">
          <v-card>
            <v-card-title class="headline">Inloggen</v-card-title>
            <v-card-text>Om gebruik te maken van deze app, moet je registeren of inloggen.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="closeModelLogin">Sluiten &amp; Inloggen</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <router-view> </router-view>
    </v-main>
  </v-app>
</template>

<style>
  @import "https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css";
  h1, h2, h3, h4, h5, h6{
    color: #607d8b;
  }
</style>

<script>
import appNavigation from "./components/appNavigation";
import { mapGetters } from "vuex";
import store from "./store";
import router from "./router";
export default {
  name: "App",

  components: {
    appNavigation
  },
  computed: {
    ...mapGetters({
      showLoginInDialog: "showLoginInDialog"
    })
  },
  methods: {
    closeModelLogin() {
      // sluit commit Nog maken + redirect naar login
      store.commit("closeLogInDialog");
      router.push({ path: "login" });
    }
  }
};
</script>