import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// const vuetifyOpts = {
//     rtl: false,
//     theme: {
//       dark: false,
//       themes: {
//         dark: {
//           primary: '#21CFF3',
//           accent: '#FF4081',
//           secondary: '#ffe18d',
//           success: '#4CAF50',
//           info: '#2196F3',
//           warning: '#FB8C00',
//           error: '#FF5252'
//         },
//         light: {
//           primary: '#A5D6A7',
//           accent: '#E91E63',
//           secondary: '#265767',
//           success: '#689F38',
//           info: '#2196F3',
//           warning: '#FB8C00',
//           error: '#FF5252'
//         }
//       }
//     }
//   }
  
export default new Vuetify(
    {
        theme: {
        themes: {
          light: {
              primary: '#4caf50',
              secondary: '#ffc107',
              accent: '#607d8b',
              error: '#f44336',
              warning: '#ffeb3b',
              info: '#00bcd4',
              success: '#8bc34a'
                }
            }
        }
    }
);


