import Vue from 'vue'
import Vuex from 'vuex'
import { } from '../store/firestore'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: null,
      isAdmin: false
    },
    matches:[],
    showLoginInDialog: false,
    error: null,
    userMatches:[]
  },

  getters: {
    user(state) {
      return state.user
    },
    isAdmin(state) {
      return state.isAdmin
    },
    showLoginInDialog(state) {
      return state.showLoginInDialog;
    },
    matches(state){
      return state.matches
    },
    userMatches(state){
      return state.userMatches
    }
  },
  mutations: {
    SET_LAST_WALK(state,value){
      state.user.lastWalkID = value
    },
    SET_MATCHES(state,value){
      state.matches = value
    },
    SET_MATCHED_USERS(state,value){
      state.userMatches = value
    },
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data) {
      // always set admin to false...
      state.user.isAdmin = false;
      state.user.data = data;
      if (data) {
        if (data.email === 'woutertinbergen@me.com') {
          state.user.isAdmin = true;
        }
      }
    },
    openLogInDialog(state) {
      state.showLoginInDialog = true;
    },
    closeLogInDialog(state) {
      state.showLoginInDialog = false;
    },
  },
  actions: {
    setLastWalk({commit}, walkid){
      commit("SET_LAST_WALK", walkid)
    },
    storeMatches({commit}, matches){
      commit("SET_MATCHES", matches)
    },
    storeMatchedUsers({commit}, users){
      commit ("SET_MATCHED_USERS", users)
    },
    fetchUser({ commit }, user) {
      // sets user details on scope
      if (user !==null && user.email) {
        commit("SET_LOGGED_IN", true);
        commit("SET_USER", {
          email:user.email,
          name: user.naam,
          uid : user.uid,
          id : user.id
        });
      } else {
        commit("SET_USER", null);
        commit("SET_LOGGED_IN", false)
      }
    },
  }
})