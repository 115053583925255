import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

// meta: { requiresLogin: true }  Voor ingelogd
// meta: { requiresAdmin: true }  Voor admin only

Vue.use(VueRouter)
  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title : "Loop een Rondje Maarn!"
    }
  },
   {
    path: '/register',
    name: 'Register',
    meta: {
      title : "Registreer voor Rondje Maarn!"
    },
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title : "Login bij Rondje Maarn!"
    },
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Admin.vue'),
    meta: { 
      requiresAdmin: true,
      title : "Admin" 
    }
  },
  {
    path: '/walk/:id',
    name: 'Walk',
    component: () => import(/* webpackChunkName: "walk" */ '../views/Walk.vue'),     
    meta: { 
      requiresLogin: true,
      title : "Details van een rondje" 
    }
  },
  {
    path: '/chat/:id',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/Chat.vue'),     
    meta: { 
      requiresLogin: true,
      title : "Chat" 
    }
  },
  {
    path: '/profile/',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: { 
      requiresLogin: true,
      title : "Jouw Profiel" 
    }
  },
  {
    path: '/wandelaars/',
    name: 'walkers',
    component: () => import(/* webpackChunkName: "walker" */ '../views/Walkers.vue'),
    meta: { 
      requiresLogin: true,
      title : "Alle Wandelaars" 
    }
  },
]

let router = new VueRouter({
  mode: 'history',
  routes
})

const DEFAULT_TITLE = 'Rondje Maarn, loop mee!';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});
// dit implementeren : https://router.vuejs.org/guide/advanced/meta.html
router.beforeEach((to,from,next) =>{
  if (to.matched.some(record => record.meta.requiresLogin)) {
    // if requiresLogin 
    if (!store.getters.user.loggedIn) { 
      store.commit('openLogInDialog');
      router.replace({ name: 'Login', query: { from: to.path } }); // query from geeft pad aan waar naartoe moet worden verwezen
    }else{ // wel ingelogd? --> door
      next()
    } 
  }else {
    // niet de login gevonden?
    // check voor admin? Admin implies ook een login....
    if (to.matched.some(record => record.meta.requiresAdmin)) {
      // if admin -->
      if (store.getters.user.isAdmin) { 
        // wel admin --> door
        next();
      }else{ // geen admin? --> door naar home
        next('/')
      } 
    }else {
      next()
    }
  }
})
export default router