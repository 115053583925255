import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
const config = {
   apiKey: "AIzaSyCTnLL2ZB4-oscCnxC0Kiny8AV9EGOhg30",
    authDomain: "rondjemaarn-524b7.firebaseapp.com",
    databaseURL: "https://rondjemaarn-524b7.firebaseio.com",
    projectId: "rondjemaarn-524b7",
    storageBucket: "rondjemaarn-524b7.appspot.com",
    messagingSenderId: "862913476863",
    appId: "1:862913476863:web:ade1946903987aa60b34ca",
    measurementId: "G-C0DQHYXWHR"
};
firebase.initializeApp(config);
const db = firebase.firestore();
const auth = firebase.auth();

auth.languageCode = 'nl';

const counterRef = db.collection('counter');
const walksRef = db.collection('walks');
const usersRef = db.collection('users');
const conversationsRef = db.collection('conversationsNew');
//const checkinRef = db.collection('checkins');
export {walksRef,auth, usersRef, counterRef, db, conversationsRef};
export default db;