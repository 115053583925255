<!-- geeft het totaal van vandaag terug -->
<template>
  <v-container>
    <v-row class="text-center font-weight-light">
        <v-col>
            Vandaag zijn er al <strong>{{wandelingen}} </strong>rondjes gepland.<br> Er zijn <strong> {{vrijeWandelaars}}</strong> vrije wandelaars beschikbaar.<span v-if="wandelingen='0'">..<br/>Dus geef snel jouw beschikbaarheid door!</span>
        </v-col>    
    </v-row>
  </v-container>
</template>

<script>
import {walksRef} from "../store/firestore";
import * as moment from "moment/moment";
export default {
  name: "walkCountToday",
  data: () => ({
      wandelingen : 0,
      vrijeWandelaars : 0
  }),
  created() {
      this.getCountToday();
  },
  methods: {
    getCountToday() {
      let that = this;
      that.wandelingen = 0;
      that.vrijeWandelaars = 0;
       walksRef
        .where("datum", "==", moment().format("YYYY-MM-DD"))
        .get()
        .then(function (querySnapshot) {
            that.vrijeWandelaars = querySnapshot.docs.length;
            querySnapshot.forEach(function (doc) {
              if(doc.data().partnerFound){
                that.wandelingen++;
                that.vrijeWandelaars--;
              }
            });
          that.wandelingen = that.wandelingen/2; // ontdubbelen
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
  },
};
</script>
