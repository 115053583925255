<template>
  <div class="home">
    <v-container class="grey lighten-5">
      <v-row>
        <v-col>
          
          <div v-if="!user.loggedIn">
             <h2 class="font-weight-light mb-3">
              Rondje Maarn
             </h2>
          
            <p class="heading font-weight-light">
              Loop je koffie- of lunchrondje met een dorpsgenoot!
            </p>
              <v-img src="../assets/cards/raadhuis-min.jpg" height="240px"></v-img>
            <div class="text-center ma-sm-10 mx-xs-3 mt-10">
              <v-btn color="primary" to="/login" class="mr-4">Login</v-btn> of
              <v-btn color="tertiary" outlined text class="ml-4" to="/register"
                >Registreer</v-btn
              >
            </div>
            <walk-count-today></walk-count-today>
            <p>
              Hoe werkt het?
            </p>
            <ol class="font-weight-light">
              <li>Geef aan wanneer je kunt</li>
              <li>Vind een loopmaatje</li>
              <li>Leg contact &amp; lopen maar!</li>
              </ol>
            <p class="mt-10 ">
              Bondige voorwaarden
            </p>
             <small class="mt-10 font-weight-light small">
              Door deze app te gebruiken maak je je een naam en mailadres bekend aan gebruikers van de app. <br>
              Deze gegevens staan op Amerikaanse servers en worden nooit verkocht.<br>
              Eigenlijk is dit een uit de hand gelopen grapje. Dank M. en vele anderen ;) 
            </small>
          </div>
          <v-row v-if="user.loggedIn">
            <v-col>
              <small>{{user.data.name}}, welkom terug!</small>
              <h2 class="font-weight-light">Jouw wandelingen op een rij</h2>
              <v-list>
                <v-list-item-group active-class="green--text">
                  <template v-for="(item, index) in yourDates">
                    <v-list-item :key="item.id">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title class="text--primary">
                            <router-link
                              style="text-decoration: none; color: #333"
                              v-if="
                                item.partnerFound ||
                                (item.match && item.match.partnerFound !== true)
                              "
                              :to="{ name: 'Walk', params: { id: item.id } }"
                            >
                              <v-icon v-if="item.partnerFound" color="green">
                                mdi-emoticon-cool-outline
                              </v-icon>
                              <v-icon v-if="!item.partnerFound" color="grey">
                                mdi-emoticon-sad-outline
                              </v-icon>
                              {{ item.datum | datumLeesbaar }}
                            </router-link>
                            <div v-else>
                              <v-icon v-if="item.partnerFound" color="green">
                                mdi-emoticon-cool-outline
                              </v-icon>
                              <v-icon v-if="!item.partnerFound" color="grey">
                                mdi-emoticon-sad-outline
                              </v-icon>
                              {{ item.datum | datumLeesbaar }}
                            </div>
                          </v-list-item-title>
                          <router-link
                            class="ml-7"
                            v-if="
                              item.partnerFound ||
                              (item.match && item.match.partnerFound !== true)
                            "
                            :to="{ name: 'Walk', params: { id: item.id } }"
                          >
                          
                            <span v-if="item.partnerFound">Loopje met {{item.partner.naam}}</span>
                            
                            <span v-if="!item.partnerFound"
                              >Kies een maatje</span
                            >
                          </router-link>
                          <small v-else>Er zijn geen wandelaars vrij...</small>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn
                              color="secondary"
                              outlined
                              fab
                              x-small
                              dark
                              @click="deleteDate(item)"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
              
                          <!-- <v-list-item-action-text >Verwijderen</v-list-item-action-text> -->
                        </v-list-item-action>
                      </template>
                    </v-list-item>

                    <v-divider
                      v-if="index < yourDates.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col>
              <div class="datepickerContainer">
                <h3 class="font-weight-light">Voeg nieuwe data toe</h3>
                <v-date-picker
                  v-model="yourNewDates"
                  multiple
                  :events="arrayEvents"
                  :allowed-dates="allowedDates"
                  event-color="green lighten-1"
                ></v-date-picker>
                <v-btn @click.native="saveMyDates()" color="primary">
                  <span class="mr-2">Bewaar je data</span>
                  <v-icon color="green darken-2">mdi-account-group </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row v-if="user.loggedIn">
            <v-col>
              <h3>Legenda</h3>
              <p class="text-caption">
                <v-icon color="green" class="mr-2">
                  mdi-emoticon-cool-outline
                </v-icon>
                <i>Je hebt iemand gevonden en bevestigd</i>
              </p>
              <p class="text-caption">
                <v-icon color="grey" class="mr-2">
                  mdi-emoticon-sad-outline
                </v-icon>
                <i>Geen match mogelijk...</i>
              </p>
              <h3>release notes</h3>
              <ul>
                <li>
                  <v-chip class="ma-2" color="orange" outlined small>
                    let op! </v-chip
                  >Er worden nog geen automatische mails gestuurd, dus neem
                  altijd even contact op
                </li>
                <li>Het gaat misschien mis, jammer dan, dit is een beta :)</li>
                <li>
                  Jouw dagen selecteren in de kalender, of verwijderen uit de
                  lijst
                </li>
                <li>
                  Een groen bolletje betekent: er is iemand, of jij :) die wil
                  lopen.
                </li>
                <li>Een grijze datum heb je al gekozen</li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style>
.datepickerContainer {
  max-width: 400px;
}
li {
  line-height: 1.7em;
}
.v-date-picker-title{
  flex-direction: row !important;
}
.v-date-picker-title__date{
 font-size:20px !important 
}
</style>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import { db, walksRef, counterRef, usersRef } from "../store/firestore";
import store from "../store/";
import * as moment from "moment/moment";
import walkCountToday from "../components/WalkCountToday.vue";
export default {
  name: "Home",
  components: {
    walkCountToday
  },
  data: () => ({
    yourDates: [],
    yourSimpleDates: [],
    yourNewDates: [],
    arrayEvents: ["2020-01-01"],
    allDates: [],
  }),
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user",
      matches: "matches",
    }),
  },
  created() {
    this.getData();
  },
  filters: {
    datumLeesbaar: function (date) {
      return moment(date).format("dddd DD MMMM 'YY");
    },
  },
  methods: {
    allowedDates(date) {
      if (this.yourSimpleDates.includes(date)) {
        return false;
      }
      return true;
    },
    cleanOldData(){
      var count = 0;
      walksRef
      .where("datum", "<", moment().format("YYYY-MM-DD"))
      .get()
      .then(function(querySnapshot) {
        // Once we get the results, begin a batch
        var batch = db.batch();
        querySnapshot.forEach(function(doc) {
            // For each doc, add a delete operation to the batch
            batch.delete(doc.ref);
            count++;
        });
        // Commit the batch
        return batch.commit();
  }).then(function() {
      // Delete completed!
      console.log("Deleted " + count +" old walks")
      // ...
  }); 
    },
    getData() {
      this.cleanOldData();
      var that = this;
      that.allDates = [];
      // haal alle events op in de toekomst
      walksRef
        .where("datum", ">=", moment().format("YYYY-MM-DD"))
        .get()
        .then(function (querySnapshot) {
          (that.arrayEvents = ["2020-01-01"]),
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              that.arrayEvents.push(doc.data().datum);
              var test = doc.data();
              test.id = doc.id;
              that.allDates.push(test);
            });
          if (that.user.loggedIn) {
            that.getMyDates();
          }
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
    saveMyDates() {
      var that = this;
      this.yourNewDates.forEach(function (date) {
        walksRef
          .add({
            naam: that.user.data.name,
            datum: date,
            uid: that.user.data.uid,
            email: that.user.data.email,
          })
          .then(function () {
            // clear selection
            that.yourNewDates = [];
            that.getData();
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      });
    },
    getMyDates() {
      var that = this;
      // haal alle events op in de toekomst
      walksRef
        .where("uid", "==", that.user.data.uid)
        .where("datum", ">=", moment().format("YYYY-MM-DD"))
        .onSnapshot((querySnapshot) => {
          (that.yourDates.splice(0)),
            (that.yourSimpleDates = []),
            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              var temp = doc.data();
              temp.id = doc.id;
              //that.yourDates.push(temp);
              Vue.set(that.yourDates, that.yourDates.length, temp)
              that.yourSimpleDates.push(doc.data().datum);
            });
          that.yourDates.sort(function (a, b) {
            a = a.datum.split("-").join("");
            b = b.datum.split("-").join("");
            return a > b ? 1 : a < b ? -1 : 0;
          });
          that.checkMatches();
        })
    },
    deleteDate(walkItem) {
      if (confirm("Wil je deze echt verwijderen?")) {
        var that = this;
        var item = walkItem;
        walksRef
          .doc(walkItem.id)
          .delete()
          .then(function () {
            that.changeCounter(-1,walkItem.uid);
            // find for partners !  TO DO
            if (item.partnerFound) {
              // remove partner infor en partnerFound, van
              // walksref () met id item.partner.id
              walksRef
                .doc(item.partner.id)
                .set(
                  {
                    partnerFound: false,
                    partner: {},
                  },
                  { merge: true }
                )
                .then(function () {
                  that.changeCounter(-1,item.partner.uid);
                  console.log("Document successfully updated!");
                })
                .catch(function (error) {
                  console.error("Error writing document: ", error);
                });
            }
            // en vernieuwen.
            that.getData();
          })
          .catch(function (error) {
            console.error("Error removing document: ", error);
          });
      }
    },
    changeCounter(value, uid) {
      counterRef
        .doc(uid)
        .get()
        .then(function (doc) {
            if(doc.exists){
                if(doc.data().count == 0 && value == -1){return false}
                else{
                  counterRef
                    .doc(uid)
                    .set({"count":  doc.data().count+ value})
                }
            }else{
              console.log("oeps")
            }
        })
        .catch(function (error) {
          console.error("Error writing document: ", error);
        });
      // einde ref update
    },
    updateCounter(){
      //admid only
      // buttong toevoegen
      // <v-btn @click="updateCounter()">Counter </v-btn>
      usersRef
        .get()
        .then(function (querySnapshot) {

            querySnapshot.forEach(function (doc) {
              // doc.data() is never undefined for query doc snapshots
              var test = doc.data();
              console.log(test);
              if(test.uid){
                counterRef
                  .doc(test.uid)
                  .set({count:0})
                  .then(function (res) {
                    console.log("Document successfully updated!", res);
                  })
                  .catch(function (error) {
                    console.error("Error writing document: ", error);
                  });
              }
            })
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
      
    },
    checkMatches() {
      var that = this;
      // returns duplicate dates
      const lookup = that.allDates.reduce((a, e) => {
        a[e.datum] = ++a[e.datum] || 0;
        return a;
      }, {});
 
      var tmp = that.allDates.filter((e) => lookup[e.datum]);
      // filter de andere er uit.
      var matchedEvents = tmp.filter(function (event) {
        return event.uid !== that.user.data.uid;
      });
      // merge matches in yourDates
      var matches = [];
      this.yourDates.forEach(function (date) {
        matchedEvents.forEach(function (match) {
          if (match.datum == date.datum) {
            //date.match = match;
            Vue.set(date, 'match', match)
            if (match.uid !== that.user.data.uid) {
              matches.push(match);
            }
          }
        });
      });
      store.dispatch("storeMatches", matches);
    },
  },
};
</script>